import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getFavoritesByItemIdOrCurationId,
  removeFavorites,
  getFavoritesByCategoryType,
} from "../../apollo/operations/favorite";
import FavoriteListType from "./FavoriteListType";
import EmptyList from "../../components/EmptyList";
import { openToast } from "../../components/toast";
import { updateCuration } from "../../apollo/operations/curation";
import FavoriteLists from "./FavoriteLists";
import { updateItemDetails } from "../../apollo/operations/items";
import IconComponent from "../../components/fields/icon";
import DropDownToggle from "../../components/MobieViewDropDown/DropDownToggle";
import { actionDropDownSort, sortList } from "../../components/helpers/sortHelper";
import ActionDropDown from "../../components/fields/ActionDropDown";
import Loader from "../../components/loaderScreen";

export default function Favorites() {
  const [updateItem] = useMutation(updateItemDetails);
  const classes = useStyles();
  const [favoriteList, setFavoriteList] = useState([]);
  const [getFavorite, { loading, data: ListOfFavorites }] = useLazyQuery(
    getFavoritesByItemIdOrCurationId,
  );
  const [getFavoritesByCategory, { data: getFavoritesByCategoryData }] = useLazyQuery(
    getFavoritesByCategoryType,
  );
  const [UpdateCuration, { data: updateCurationData }] = useMutation(updateCuration);
  const [deleteFavorite, { data: deleteFavoriteData }] = useMutation(removeFavorites);

  const [favoriteByType, setFavoriteByType] = useState([]);
  const [look, setLook] = useState({});
  const [favoriteTypeList, setFavoriteTypeList] = useState({
    itemType: [{ name: "View All", isActive: true }],
    curationType: [],
  });
  const [sortedFavorites, setSortedFavorites] = useState([]);
  const [sortName, setSortName] = useState("");
  const [dropdownToggle, setDropdownToggle] = useState(false);

  useEffect(() => {
    setSortedFavorites(() => favoriteByType);
    onToggleValueChange(sortList[2]);
  }, [favoriteByType]);

  useEffect(() => {
    getFavorite({ variables: {} });
  }, [deleteFavoriteData, updateCurationData]);

  useEffect(() => {
    if (
      ListOfFavorites?.getFavoritesByItemIdOrCurationId?.data &&
      ListOfFavorites?.getFavoritesByItemIdOrCurationId?.data?.length > 0
    ) {
      setFavoriteList(ListOfFavorites?.getFavoritesByItemIdOrCurationId?.data);
      setFavoriteByType(ListOfFavorites?.getFavoritesByItemIdOrCurationId?.data);
    } else {
      setFavoriteList([]);
      setFavoriteByType([]);
    }
  }, [ListOfFavorites]);

  useEffect(() => {
    for (const [fav] of favoriteList.entries()) {
      if (fav.curationId) {
        let i = favoriteTypeList.curationType.findIndex((item) => item.name === fav.curationLook);
        if (i === -1) {
          favoriteTypeList.curationType.push({
            name: fav.curationLook,
            isActive: false,
          });
        }
      } else {
        let i = favoriteTypeList.itemType.findIndex((item) => item.name === fav.itemList?.category);
        if (i === -1) {
          favoriteTypeList.itemType.push({
            name: fav.itemList?.category,
            isActive: false,
          });
        }
      }
    }
    favoriteTypeList.curationTypeName = "My Curations";
    favoriteTypeList.curationTypeToggle = false;
    setFavoriteTypeList({ ...favoriteTypeList });
  }, [favoriteList]);

  useEffect(() => {
    if (favoriteList?.length > 0 && look?.name?.toLowerCase() !== "view all") {
      getFavoritesByCategory({
        variables: {
          favoritesFilterData: {
            categoryName: look.name,
            isCuration: look.isCuration,
          },
        },
      });
    } else if (favoriteList?.length > 0 && look?.name?.toLowerCase() === "view all") {
      setFavoriteByType(favoriteList);
    } else {
      setFavoriteByType([]);
    }
  }, [look]);

  useEffect(() => {
    if (
      getFavoritesByCategoryData &&
      getFavoritesByCategoryData?.getFavoritesByCategoryType?.data
    ) {
      setFavoriteByType(getFavoritesByCategoryData.getFavoritesByCategoryType.data);
    } else {
      setFavoriteByType([]);
    }
  }, [getFavoritesByCategoryData]);

  function likedItem(value, outfits) {
    if (value) {
      deleteFavorite({
        variables: {
          favoriteData: {
            id: outfits.favoriteId.toString(),
          },
        },
      });
      if (outfits.curationId) {
        UpdateCuration({
          variables: {
            curationData: {
              curationId: outfits.curationId.toString(),
              isLiked: false,
            },
          },
        });
      }
      if (outfits.itemId) {
        updateItem({
          variables: {
            updateItems: {
              isLiked: false,
              id: outfits.itemId,
            },
          },
        });
      }
    }
  }

  useEffect(() => {
    if (deleteFavoriteData) {
      if (deleteFavoriteData.removeFavorites?.statusCode === "200") {
        openToast("success", "Successful!", "Item is remove from favorites");
      } else {
        openToast("error", "Failed!", "Try Again");
      }
    }
  }, [deleteFavoriteData]);

  function setType(cat, index, isCuration) {
    setFavoriteTypeList((prevList) => ({
      ...prevList,
      curationType: prevList.curationType.map((item, i) =>
        i === index ? { ...item, isActive: isCuration } : { ...item, isActive: false },
      ),
      itemType: prevList.itemType.map((item, i) =>
        i === index ? { ...item, isActive: !isCuration } : { ...item, isActive: false },
      ),
    }));
    setLook({ name: cat.name, isCuration });
  }

  function handleToggle(key) {
    favoriteTypeList[key] = !favoriteTypeList[key];
    setFavoriteTypeList({ ...favoriteTypeList });
  }
  function onToggleValueChange(selectedItem) {
    setSortedFavorites(
      actionDropDownSort(
        selectedItem,
        selectedItem.name.includes("to") ? "curationName" : "createdAt",
      ),
    );
    setSortName(() => selectedItem.name);
  }

  return (
    <div className={classes.parentContainer}>
      <div className={classes.title}>FAVORITES</div>
      <div className={classes.mainContainer}>
        <FavoriteListType list={favoriteTypeList} setType={setType} />
        <DropDownToggle
          dropDownList={favoriteTypeList}
          lookName={look.name}
          defaultName="My Favorites"
          orderToBeShown={["itemType", "curationType"]}
          setType={setType}
          setSubCategoryToggle={handleToggle}
        />
        <div className={classes.rightContainer}>
          <hr className={classes.title} />
          {!loading ? (
            <>
              <div className={classes.rightHeader}>
                <div className={classes.itemsCount}>
                  {sortedFavorites?.length ? sortedFavorites?.length : 0} ITEMS
                </div>
                <div
                  className={classes.sortOption}
                  onClick={() => setDropdownToggle((preVal) => !preVal)}
                >
                  Sort By {sortName}
                  <IconComponent iconTitle={dropdownToggle ? "UpArrow" : "DownArrow"} />
                  {dropdownToggle && (
                    <ActionDropDown
                      menuItemList={sortList}
                      setToggle={setDropdownToggle}
                      onValueChange={onToggleValueChange}
                    />
                  )}
                </div>
              </div>
              {sortedFavorites?.length > 0 ? (
                <div className={`${classes.curationContainer} ${classes.favCurationContainer}`}>
                  {sortedFavorites?.map(
                    (outfits, index) =>
                      outfits && (
                        <FavoriteLists
                          outfits={outfits}
                          key={index}
                          index={index}
                          likedItem={likedItem}
                        />
                      ),
                  )}
                </div>
              ) : (
                <EmptyList title={"Favorite"} />
              )}
            </>
          ) : <Loader />
          }
        </div>
      </div>
    </div>
  );
}
