import { gql } from "@apollo/client";

export const getItemsFromEmail = gql`
  query GetItemsFromEmail($email: EmailAddress!, $from: String!, $to: String!) {
    getItemsFromEmail(email: $email, from: $from, to: $to) {
      statusCode
    }
  }
`;

export const getItemsFromTempTable = gql`
  query GetItemsFromTempTable {
    getItemsFromTempTable {
      items{
        id
        userId
        store
        itemName
        brand
        category
        size
        color
        tags
        src
        productUrl
        isLiked
        isDeleted
        datePurchased
        price
        subCategory
        deliveryDate
        material
        fit
        pattern
        details
        design
        isItemAdded
      }
      isPending
    }
  }
`;

export const removeItemFromTempTable = gql`
  mutation removeItemFromTempTable($itemId: Int!) {
    removeItemFromTempTable(itemId: $itemId) {
      statusCode
    }
  }
`;

export const addItemToCloset = gql`
  mutation AddItemToCloset($addItems: AddItems!) {
    addItemToCloset(addItems: $addItems) {
      statusCode
      message
      error
      data
    }
  }
`;

export const getItemsFromCloset = gql`
  query GetItemsFromCloset($category: [String]!) {
    getItemsFromCloset(category: $category) {
      id
      brand
      store
      itemName
      color
      size
      tags
      src
      productUrl
      productStoreUrl
      isLiked
      category
      createdAt
      modifiedAt
      datePurchased
      price
    }
  }
`;

export const getItems = gql`
  query GetItemsFromCloset($category: [String]!) {
    getItemsFromCloset(category: $category) {
      id
      src
      brand
      category
      itemName
      productUrl
    }
  }
`;

export const addItemByUploadImage = gql`
  mutation AddItemToClosetByImageUpload($addImageItem: ImageItems!) {
    addItemToClosetByImageUpload(addImageItem: $addImageItem) {
      statusCode
      message
      error
    }
  }
`;

export const uploadImage = gql`
  query GetPresignedUploadURL($file: fileDetails!) {
    getPresignedUploadURL(file: $file) {
      url
      key
    }
  }
`;

export const uploadArrayImages = gql`
query GetArrayPreignedUploadURL($arrayFile: [fileDetails]!) {
  getArrayPresignedUploadURL(arrayFile: $arrayFile) {
    statusCode
    message
    error
    data
  }
}
`;

export const uploadProfileImage = gql`
  mutation GetUploadProfileImageURL($file: file!) {
    getUploadProfileImageURL(file: $file) {
      statusCode
      message
      error
      data
    }
  }
`;

// export const getURLProduct = gql`
// query GetUploadedUrlDetails($url: URL!) {
//   getUploadedUrlDetails(url: $url) {
//     statusCode
//     message
//     error
//     data
//   }
// }
// `;

export const getURLProduct = gql`
  query GetProductFromUrl($url: URL!) {
    getProductFromUrl(url: $url) {
      statusCode
      message
      error
      data
    }
  }
`;

export const removeItemFromCloset = gql`
  mutation RemoveItemFromCloset($removeItems: RemoveItems!) {
    removeItemFromCloset(removeItems: $removeItems) {
      statusCode
      message
      error
      data
    }
  }
`;

export const updateItemDetails = gql`
  mutation UpdateItemDetails($updateItems: UpdateItems!) {
    updateItemDetails(updateItems: $updateItems) {
      statusCode
      message
      error
      data
    }
  }
`;
