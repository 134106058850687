import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  curationDetails: {
    height: "fit-content",
    position: "relative",
  },
  CurationsListContainer: {
    border: "1px solid #000000",
    display: "flex",
    marginBottom: "10px",
    flexWrap: "wrap",
    position: "relative",
    height: "280px",
  },
  CurationsListImages: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(70px, 1fr))",
    overflow:"unset",
    height: "100%",
    width: "100%",
    gap:"0",

    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },
  outfitImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "fit-content",
    objectFit: "contain",
  },
  addVector: {
    position: "absolute",
    left: "23.8%",
    right: "93.16%",
    top: "24.3%",
    bottom: "69.19%",
  },
  vector: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: "20px",
    height: "20px",
    transform: "translate(-50%, -50%)",
    "& > svg": {
      width: "20px",
      height: "20px",
    }
  },
  itemDetails: {
    width: "100%",
    fontWeight: "bold",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    padding: "10px 20px",
    fontFamily: "var(--primaryRegularFont)",
    letterSpacing: "0.03em",
  },
  ItemsCard: {
    cursor: "pointer",
    position: "absolute",
    right: 0,
  },
  icon: {
    filter: "invert(1)",
  },
  itemImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  itemContainer: {
    width: "100%",
    height: "250px",
  },

  list: {
    display: "flex",
    height: "fit-content",
    maxHeight: "280px",
  },

  ...mobileMediaStyle({
    CurationsListContainer: {
      width: "unset",
      margin: 0,
    },
    outfitImage: {},
    CurationsListImages: {
      gridTemplateColumns: "repeat(auto-fit, minmax(40px, 1fr))",
      gap: "5px",

      "&::-webkit-scrollbar": {
        width: "2px",
        height: "2px",
      },
    },
    itemDetails: {
      padding: "10px 0 0 0",
      fontSize: "10px",
    },
    ItemsCard: {
      width: "12px",
      height: "12px",
    },
    icon: {
      objectFit: "contain",
      width: "100%",
      height: "100%",
    },
    eclipseVector: {
      width: "18px",
      height: "18px",
    },
    vector: {
      width: "14px",
      height: "14px",
      "& > svg": {
        width: "14px",
        height: "14px",
      }
    },
    list: {
      maxHeight: "100px",
    },
  }, "@media (max-width:600px)"),
});
