import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useStyles } from "./styles";
export default function SkeletonLoader(props) {
    const classes = useStyles();
    const { count } = props;
    return <>
     {Array(count).fill(null).map((_, index) => (
        <div key={index} className={classes.parent}>
          <Skeleton className={classes.imgBody} />
        </div>
      ))}
   </>
}