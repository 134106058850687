import { useStyles } from "./styles";
import ThreeDotsIcon from "../../assets/svg/threeDotsIcon.svg";
import CardOption from "../CardOption";
import { memo, useEffect, useState } from "react";
import EditCuration from "../../pages/CurateMe/EditCuration";
import ModalPortal from "../../containers/common/modalPortal";
import Modal from "../../containers/common/modalPortal/modal";
import IconComponent from "../fields/icon";
import { LikeIcon } from "../../containers/StyleMe";
import { useMutation } from "@apollo/client";
import { addFavorites, removeFavorites } from "../../apollo/operations/favorite";
import { openToast } from "../toast";
import CurationBoxes from "../curationBoxes/curationBox";

function CurationLists(props) {
  const {
    outfits,
    removeCuration,
    index,
    updateCurationsDetails,
    handleCurateUpdate,
    dragStart,
    sortedCuration,
  } = props;

  const [addFavorite, { data: favorite }] = useMutation(addFavorites);
  const [deleteFavorite] = useMutation(removeFavorites);

  const [showCurationOption, setShowCurationOption] = useState(false);
  const [showEditCuration, setShowEditCuration] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [removeCurationData, setRemoveCurationData] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if (favorite?.addFavorites) {
      if (favorite.addFavorites?.statusCode === "200") {
        openToast("success", "Successful!", "Curation added to favorites");
      } else {
        openToast("error", "Failed!", "Try Again");
      }
    }
    if (removeCurationData?.removeCurations) {
      if (removeCurationData.removeCurations?.statusCode === "200") {
        openToast("success", "Successful!", "Curation deleted");
      } else {
        openToast("error", "Failed!", "Try Again");
      }
    }
  }, [removeCurationData, favorite]);

  function handleOpenCard() {
    setShowCurationOption(!showCurationOption);
  }

  function updateCurations(curation) {
    updateCurationsDetails(curation);
  }
  const handleAddFavorite = (outfitsId) => {
    addFavorite({
      variables: {
        favoriteData: {
          curationId: outfitsId,
          isLiked: true,
        },
      },
    });
  };

  const handleRemoveFavorite = (outfitsId) => {
    deleteFavorite({
      variables: {
        favoriteData: {
          curationId: outfitsId,
        },
      },
    });
  };

  function handleLike(value) {
    if (value) {
      handleCurateUpdate({
        curationId: outfits.id.toString(),
        isLiked: value,
      });
      handleAddFavorite(outfits.id.toString());
    } else {
      if (outfits.id) {
        handleCurateUpdate({
          curationId: outfits.id.toString(),
          isLiked: value,
        });
        handleRemoveFavorite(outfits.id.toString());
      }
    }
  }

  function addItemModalButton(value) {
    setShowConfirmationModal(false);
    if (value) {
      removeCuration(removeCurationData.index, removeCurationData.outfits);
    }
  }

  return (
    <div className={classes.curationDetails} draggable onDragStart={() => dragStart(outfits)}>
      <LikeIcon>
        <IconComponent
          className={classes.vector}
          iconTitle={outfits.isLiked ? "LikedVector" : "UnLikedVector"}
          onClick={() => handleLike(!outfits.isLiked)}
        />
      </LikeIcon>
      <div className={classes.CurationsListContainer} id={index}>
        <div className={classes.CurationsListImages}>
          {outfits?.curationList?.length > 0 &&
            <CurationBoxes
              curations={outfits?.curationList}
            />
          }
          {outfits?.itemList?.src && (
            // <div className={classes.list} key={`index-${index}`}>
            <div className={classes.itemContainer}>
              <img
                className={classes.itemImg}
                draggable={false}
                src={outfits.itemList?.src}
                key={`src-${index}`}
              />
            </div>
            // </div>
          )}
        </div>
      </div>
      <div className={classes.itemDetails}>
        {outfits?.curationName
          ? outfits?.curationName
          : outfits?.itemName
          ? outfits?.itemName
          : outfits?.itemList?.itemName}
        <div
          className={classes.ItemsCard}
          onClick={(event) => {
            event.stopPropagation();
            handleOpenCard();
          }}
        >
          <img src={ThreeDotsIcon} className={classes.icon} />
          {showCurationOption && (
            <CardOption
              outfits={outfits}
              index={index}
              showCuration={setShowCurationOption}
              showEditMenu={setShowEditCuration}
              setShowConfirmationModal={setShowConfirmationModal}
              removeCurationDetails={setRemoveCurationData}
            />
          )}
        </div>
      </div>
      {showEditCuration && (
        <EditCuration
          outfits={outfits}
          sortedCuration={sortedCuration}
          showAddCurationsPopup={setShowEditCuration}
          showCuration={setShowCurationOption}
          updateCurationsDetails={updateCurations}
        />
      )}
      {showConfirmationModal && (
        <ModalPortal>
          <Modal type="removeCuration" handleButtonClick={addItemModalButton} svgType="circle" />
        </ModalPortal>
      )}
    </div>
  );
}

export default memo(CurationLists);
