import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  FavoritesListContainer: {
    border: "1px solid #000000",
    height: "280px",
    minHeight: "280px",
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
  },
  FavoritesListImages: {
    display: "grid",
    overflow: "auto",
    gap: "10px",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(70px, 1fr))",

    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },
  outfitImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "fit-content",
    objectFit: "contain",
  },
  addVector: {
    position: "absolute",
    left: "23.8%",
    right: "93.16%",
    top: "24.3%",
    bottom: "69.19%",
  },
  actionField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    width: "32px",
    borderRadius: "16px",
    position: "absolute",
    "& > svg": {
      height: "20px",
      width: "20px",
    }
  },
  likeVector: {
    top: "6px",
    right: "6px",
  },
  itemDetails: {
    width: "100%",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: "10px 20px",
    textAlign: "center",
    fontFamily: "var(--primaryRegularFont)",
    fontStyle: "normal",
    fontSize: "16px",
    letterSpacing: "0.03em",
  },

  icon: {
    filter: "invert(1)",
  },
  itemImg: {
    width: "fit-content",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  itemContainer: {
    display: "flex",
    height: "fit-content",
    maxHeight: "280px",
  },
  FavoritesDetails: {
    height: "fit-content",
  },

  ...mobileMediaStyle({
    FavoritesListContainer: {
      width: "unset",
      margin: 0
    },

    FavoritesListImages: {
      gridTemplateColumns: "repeat(auto-fit, minmax(40px, 1fr))",

      "&::-webkit-scrollbar": {
        width: "2px",
        height: "2px",
      },
    },

    itemDetails: {
      padding: "10px 0 0 0",
      fontSize: "10px",
    },

    itemContainer: {
      maxHeight: "100px",
    },

    actionField: {
      width: "18px",
      height: "18px",
      "& > svg": {
        width: "14px",
        height: "14px",
      },
    },

    likeVector: {
      top: "6px",
      right: "6px",
    },

    outfitImage: {},
  }, "@media (max-width:600px)"),
});
