import { useStyles } from "./styles";
  import { ReactComponent as Arrow } from "../../../src/assets/svg/arrowRightLinear.svg";
import { useState, useEffect } from "react";

export default function Pagination({ curationsData: curationsDataLength, sliderRef, slide }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const responsive = [
    { viewWidth: window.matchMedia("(max-width: 480px)").matches, size: 1 },
    { viewWidth: window.matchMedia("(min-width: 481px) and (max-width: 767px)").matches, size: 1 },
    {
      viewWidth: window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches,
      size: 2,
    },
    {
      viewWidth: window.matchMedia("(min-width: 1025px) and (max-width: 1299px)").matches,
      size: 2,
    },
    { viewWidth: window.matchMedia("(min-width: 1300px)").matches, size: 3 },
  ];

  const pageSize = responsive.find((viewSize) => viewSize.viewWidth).size;
  
  const handleOffSet = (value) => {
    // console.log({pageSize, page})
    if (value === "back") {
      if (page >= 1) {
        let currentPage = page - 1;
        sliderRef.current.slickGoTo(page * pageSize - pageSize);
        setPage(currentPage);
      }
    } else if (value === "next") {
      if (curationsDataLength > (page + 1) * pageSize) {
        setPage(page + 1);
        sliderRef.current.slickGoTo(page * pageSize + pageSize);
      }
    } 
  };
  
  useEffect(() => {
    setPage(Math.floor(slide/pageSize))
  }, [slide])

  return (
    <div className={classes.paggination} style={{ fontFamily: "var(--secondaryHeaderFont)" }}>
      <p>
        {`${curationsDataLength ? page * pageSize + 1 : curationsDataLength} - ${
          (page + 1) * pageSize > curationsDataLength ? curationsDataLength : (page + 1) * pageSize
        } of ${curationsDataLength}`}
      </p>
      <Arrow className={classes.arrowLeft} onClick={() => handleOffSet("back")} />
      <Arrow className={classes.arrowRight} onClick={() => handleOffSet("next")} />
    </div>
  );
}
