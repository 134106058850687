import { gql } from "@apollo/client";

export const getCurationsList = gql`
  query GetCurationsList {
    getCurationsList {
      statusCode
      message
      error
      data
    }
  }
`;

export const getTopFiveCurations = gql`
query GetTopFiveCurations($refresh: Boolean) {
    getTopFiveCurations(refresh: $refresh) {
      statusCode
      message
      data
      error
    }
  }
`;

export const getChatResponseQuery = gql`
  query GetChatResponse($message: String!) {
    getChatResponse(message: $message) {
      statusCode
      message
      data
      error
    }
  }
`;

export const getSelectedLookQuery = gql`
query GetSelectedLook($selectedLook: SelectedLook!, $refresh: Boolean) {
    getSelectedLook(selectedLook: $selectedLook, refresh: $refresh) {
      statusCode
      message
      data
      error
    }
  }
`;

export const addCurations = gql`
  mutation AddCurations($curationData: Curation!) {
    addCurations(curationData: $curationData) {
      statusCode
      message
      error
      data
    }
  }
`;

export const removeCuration = gql`
  mutation RemoveCurations($curationId: String!) {
    removeCurations(curationId: $curationId) {
      data
      error
      message
      statusCode
    }
  }
`;
export const updateCuration = gql`
  mutation UpdateCurations($curationData: UpdateCuration!) {
    updateCurations(curationData: $curationData) {
      statusCode
      message
      error
      data
    }
  }
`;

export const getCurationByCurationLook = gql`
  query GetCurationByCurationLook($curationLook: String!) {
    getCurationByCurationLook(curationLook: $curationLook) {
      data
      error
      message
      statusCode
    }
  }
`;

export const getRandomInspired = gql`
  query GetRandomInspired {
    getRandomInspired {
      statusCode
      message
      error
      data
    }
  }
`