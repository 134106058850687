import { useStyles } from "../styles";
import IconComponent from "../../../components/fields/icon";
import { AddIcon, LikeIcon, List, ListItems } from "../../../containers/StyleMe";
import AccessoriesTag from "./Accessories/Accessories";
import ClothesTag from "./Clothes";
import FootwearTag from "./Footwear";
import OuterwearTag from "./Outerwear";
import { useState, useEffect } from "react";

const Clothes = {
  Activewear: true,
  Beachwear: true,
  Denim: true,
  Dresses: true,
  Loungewear: true,
  Miscellaneous: true,
  Home: true,
  Jumpsuit: true,
  Suits: true,
  Shirts: true,
  Tops: true,
  Pants: true,
  Shorts: true,
  Skirts: true,
};

const Accessories = { Accessories: true, Beauty: true };
const Footwear = { Shoes: true };
const Outerwear = { Outerwear: true };

export default function Curations(props) {
  const {
    linearCategories,
    showAddCurationsPopup,
    outfits,
    addOutfits,
    removeCurationItem,
    index,
    id,
    isAdded,
    isLiked,
    addToIsAdded,
    addCurationToFavorite,
    isSelected,
    handleSelected,
    removeFromFavorite,
    removeAddCurationItem,
    key,
    showCrossIcon
  } = props;
  const classes = useStyles();
  const [isAccessory, setIsAccessory] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  let outFits2 = outfits;
  for (let element of outFits2) {
    if (element && !element?.mainCategory) {
      element.mainCategory = linearCategories[element.category];
    }
  }
  const clothesList = outFits2.filter((item) => Clothes[item?.mainCategory]);
  const accessoriesList = outFits2.filter((item) => Accessories[item?.mainCategory]);
  const footwearList = outFits2.filter((item) => Footwear[item?.mainCategory]);
  const outerwearList = outFits2.filter((item) => Outerwear[item?.mainCategory]);
  function getIds(outfits) {
    return outfits?.reduce((update, cur) => {
      update.push(cur.id);
      return update;
    }, []);
  }
  function handlePopup(value) {
    if (isLiked && !isAdded) {
      addToIsAdded(id, index);
    } else if (!isAdded) {
      showAddCurationsPopup(true, index);
      addOutfits({ outfits, isLiked: value, isAdded, index });
    } else {
      const itemIds = getIds(outfits);
      addCurationToFavorite({ curation: itemIds, isLiked: true, index });
    }
  }

  function onDropItem(e) {
    props.onDropCurationContainer(e);
  }

  function onDragOverCompleted(e) {
    e.preventDefault();
  }
  function removeAddItemFromCuration() {
    const itemIds = getIds(outfits);
    removeAddCurationItem(itemIds, index);
  }
  function removeCurationFromFavorite() {
    const itemIds = getIds(outfits);
    removeFromFavorite(itemIds, index);
  }

  useEffect(() => {
    if(!isAccessory && currentIndex !== null){
      onDropItem(currentIndex);
    }
    setCurrentIndex(null);
    setIsAccessory(false);
  }, [isAccessory, currentIndex]);

  return (
    <div
      className={`${classes.CurationsContainer} ${isSelected && "selected-item"}`}
      id={props.index}
      onDragOver={(event) => {
        event.preventDefault();
        onDragOverCompleted(event);
      }}
      onDrop={(event) => {
        event.preventDefault();
        setCurrentIndex(index);
      }}
      onClick={(event) => {
        event.stopPropagation();
        if(showCrossIcon){
          handleSelected(index);
        }
      }}
      key={key}
    >
      <AddIcon
        onClick={(event) => {
          event.stopPropagation();
          isAdded ? removeAddItemFromCuration() : handlePopup(false);
        }}
      >
        <IconComponent
          className={classes.vector}
          iconTitle={isAdded ? "AddVector" : "UnSelectedVector"}
        />
      </AddIcon>
      <LikeIcon
        onClick={(event) => {
          event.stopPropagation();
          isLiked ? removeCurationFromFavorite() : handlePopup(true);
        }}
      >
        <IconComponent
          className={classes.vector}
          iconTitle={isLiked ? "LikedVector" : "UnLikedVector"}
        />
      </LikeIcon>

      <ListItems className={`custom-items-box ${(outerwearList.length <= 0 && accessoriesList.length <= 0 && footwearList.length <= 0) && "custom-list-items"}`}>
        <ClothesTag
          clothesList={clothesList}
          removeCurationItem={removeCurationItem}
          parentIndex={index}
          classes={classes}
          showCrossIcon={showCrossIcon}
        />
        <div
          style={{
            display: "flex",
            rowGap: "0px",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
          className={`accessoriesFootwearDiv ${(outerwearList.length <= 0 && accessoriesList.length <= 0 && footwearList.length <= 0) && "d-none"}`}
        >
          <OuterwearTag
            outerwearList={outerwearList}
            removeCurationItem={removeCurationItem}
            parentIndex={index}
            classes={classes}
            accessoriesLength={accessoriesList.length}
            footwearLength={footwearList.length}
            showCrossIcon={showCrossIcon}
          />
          <AccessoriesTag
            linearCategories={linearCategories}
            accessoriesList={accessoriesList}
            removeCurationItem={removeCurationItem}
            parentIndex={index}
            classes={classes}
            footwearLength={footwearList.length}
            setIsAccessory={setIsAccessory}
            setCurrentIndex={setCurrentIndex}
            OutWearLength={outerwearList.length}
            onDropCurationContainer={props.onDropCurationContainer}
            showCrossIcon={showCrossIcon}
          />
          <FootwearTag
            footwearList={footwearList}
            removeCurationItem={removeCurationItem}
            parentIndex={index}
            classes={classes}
            showCrossIcon={showCrossIcon}
          />
        </div>

      </ListItems>
      {/* <ListItems style={{ gridTemplateColumns: `repeat(${outfits.lenght }, auto)` }}>
        {outfits.map((item, itemIndex) => (
          <List key={`index-${itemIndex}`}>
            <IconComponent
              iconTitle="CrossIcon"
              className={classes.trash}
              onClick={(event) => {
                event.stopPropagation();
                removeCurationItem(itemIndex, index);
              }}
            />
            <img className={classes.outfitImage} src={item.src} key={`src-${itemIndex}`} />
          </List>
        ))}
      </ListItems> */}
    </div>
  );
}
