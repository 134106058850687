import IconComponent from "../../../../components/fields/icon";
import { List } from "../../../../containers/StyleMe";

export default function SubCurations({
  subItemList,
  removeCurationItem,
  parentIndex,
  classes,
  subCategories,
  onDropCurationContainer,
  setIsAccessory,
  showCrossIcon,
  setCurrentIndex
}) {
  return (
    <div
      className={`subItemList${subItemList?.length}`}
    >
      {subItemList?.map((item, itemIndex) => (
        <List
          key={`index-${itemIndex}`}
          style={{
            display: "flex",
            position: "relative",
            paddingBottom: "0px",
            justifyContent: "center",
          }}
          data-category={item?.category}
          data-maincategory={item?.mainCategory}
          onDrop={(event) => {
            event.preventDefault();
            setIsAccessory(true);
            onDropCurationContainer(parentIndex, item.src);
            setCurrentIndex(null);
          }}
        >
          {showCrossIcon &&(<IconComponent
            style={{ position: "absolute", right: "0" }}
            iconTitle="CrossIcon"
            className={classes.trash}
            onClick={(event) => {
              event.stopPropagation();
              removeCurationItem(item?.id, parentIndex);
            }}
          />)}

          <img
            alt=""
            style={
              subCategories.some(
                (ListItem) => item?.category.includes(ListItem) || item.mainCategory === "Beauty",
              )
                ? {
                  maxWidth: "100%",
                  maxHeight: "125px",
                  minWidth: "100%",
                  minHeight: "125px",
                  width: "100%",
                  objectFit: "contain",
                }
                : { width: "80px" }
            }
            draggable={false}
            className={classes.outfitImage}
            src={item?.src}
            key={`src-${itemIndex}`}
          />
        </List>
      ))}
    </div>
  );
}
