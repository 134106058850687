import { createSlice } from "@reduxjs/toolkit";

export const isTempItemsLoadedSlice = createSlice({
  name: "isTempItemsLoaded",
  initialState: true,
  reducers: {
    setisTempItemsLoaded: (state, action) => action.payload,
  },
});

export const { setisTempItemsLoaded } = isTempItemsLoadedSlice.actions;

export default isTempItemsLoadedSlice.reducer;
