import IconComponent from "../../../components/fields/icon";
import { List } from "../../../containers/StyleMe";

import "../recommandation.css";

export default function ClothesTag({ clothesList, removeCurationItem, parentIndex, classes, showCrossIcon }) {
  const GroupList = clothesList.sort((a, b) => {
      if (a.categoryGroup === "topWear" && b.categoryGroup === "bottomWear") {
          return -1;
      }
      if (a.categoryGroup === "bottomWear" && b.categoryGroup === "topWear") {
          return 1;
      }
      return 0;
    });

  return (
    <div
      style={{
        // display: "flex",
        display: "-webkit-flex",
        flexDirection: "column",
        height: "100%",
      }}
      className={`GroupList${GroupList?.length}`}
    >
    
      {GroupList?.map((item, itemIndex) => (
        <List
          key={`index-${itemIndex}`}
          data-category={item?.category}
          data-maincategory={item?.mainCategory}
          style={
            ["skirts", "shorts", "pants", "denim"].includes(item?.mainCategory?.toLowerCase())
              ? { gridRowStart: 2 }
              : {}
          }
        >
          {
            showCrossIcon &&(<IconComponent
              iconTitle="CrossIcon"
              className={classes.trash}
              onClick={(event) => {
                event.stopPropagation();
                removeCurationItem(item?.id, parentIndex);
              }}
            />)
          }
          <img
            alt=""
            style={{
              maxWidth: "150px",
              maxHeight: item?.categoryGroup === "completeWear" ? "350px" : "175px",
              minWidth: item?.categoryGroup === "completeWear" ? "100px" : "150px",
              minHeight: item?.categoryGroup === "completeWear" ? "350px" : "175px",
              objectFit: "contain",
            }}
            src={item?.src}
            draggable={false}
            key={`src-${itemIndex}`}
          />
        </List>
      ))}
    </div>
  );
}
