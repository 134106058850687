export const lookTypes = [
"an white dress for a white party",
"an outfit to wear for brunch",
"an outfit for a wedding, what do you suggest?",
"an outfit for a business meeting, any recommendations?",
"an outfit for a friend's baby shower",
"an outfit for an winter weekend getaway",
"an outfit for a rooftop cocktail party.",
"a cozy winter date night?",
"an outfit for a friend's milestone birthday celebration.”",
"an outfit for a job interview",
"an outfit for a first date",
"an outfit for a casual day out, what should I wear?",
"a stylish yet comfortable outfit for traveling?",
"an outfit for a black-tie event, what do you recommend?",
"an outfit for a summer party, what would be perfect?",
"a outfit for a music festival?",
"an outfit for a romantic dinner date, any ideas?",
"an outfit for a friend’s birthday, what should I choose?",
"an outfit for traveling, what’s stylish and comfortable?",
"an outfit for a friend’s graduation ceremony",
"an outfit for a night out at a club?",
"an outfit for a day at the park?",
"an outfit for a beach wedding, what do you suggest?",
"an outfit for a semi-formal birthday party",
"an outfit a day at a theme park?",
"an outfit for a cozy movie night?",
"an outfit for a professional conference",
"an outfit for a job interview at a corporate office",
"an outfit for a holiday party, what’s festive and chic?",
"an outfit for a weekend brunch, what would look great?",
"an outfit for a night out",
"an outfit for a cocktail party, what should I wear?",
"an outfit for a holiday party?",
"an outfit for a casual day planned, what’s a good look?",
"an outfit for a music festival?",
"an outfit for a professional yet fashionable outfit for a conference.",
"an outfit for a friend’s graduation ceremony",
"an outfit for a shopping day, what’s trendy and comfortable?",
"an outfit for a workout, what’s stylish and functional?",
"an outfit for a family reunion, what should I wear?",
"an outfit for a friend’s engagement party, what do you suggest?",
"an outfit for a comfortable yet stylish travel outfit?",
"an outfit for a summer barbecue?",
"an outfit for a friend’s baby shower",
"an outfit for a chic outfit for a gallery opening",
"an outfit for a concert, what’s the best look?",
"an outfit for a formal dinner, what would be perfect?",
"an outfit for a family gathering, what should I wear?",
];