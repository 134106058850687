import IconComponent from "../../../components/fields/icon";
import { List } from "../../../containers/StyleMe";

export default function FootwearTag({
  footwearList,
  removeCurationItem,
  parentIndex,
  classes,
  showCrossIcon,
}) {
  return (
    <div style={{ gridColumn: "2" }} className={`footwearList${footwearList.length}`}>
      {footwearList.map((item, itemIndex) => (
        <List
          key={`index-${itemIndex}`}
          style={{ position: "relative", justifyContent: "center", display: "flex" }}
          data-category={item.category}
        >
          {showCrossIcon && (
            <IconComponent
              style={{ position: "absolute" }}
              iconTitle="CrossIcon"
              className={classes.trash}
              onClick={(event) => {
                event.stopPropagation();
                removeCurationItem(item.id, parentIndex);
              }}
            />
          )}

          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100px",
              minWidth: "100%",
              minHeight: "100px",
              width: "100%",
              objectFit: "contain",
            }}
            className={classes.outfitImage}
            src={item.src}
            key={`src-${itemIndex}`}
            draggable={false}
          />
        </List>
      ))}
    </div>
  );
}
