import AddCurations from "./AddCurations";
import { useStyles } from "./styles";
import SelectedItems from "./SelectedItems/selectedItems";
import CurationsContainer from "./Curations/Curations";
import Inspired from "./Inspired/index";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "./pagination";
import Modal from "../../containers/common/modalPortal/modal";
import ModalPortal from "../../containers/common/modalPortal";
import { createRef, useEffect, useMemo, useState } from "react";
import { useStyles as skeletonStyle } from "../AddYourStyle/ScanEmail/AddNewItem/styles";
import { lookTypes } from "./static/looks";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  addCurations,
  getCurationsList,
  updateCuration,
  removeCuration,
  getRandomInspired,
  getTopFiveCurations,
  getChatResponseQuery,
  getSelectedLookQuery,
} from "../../apollo/operations/curation";
import { useMutation, useLazyQuery } from "@apollo/client";
import { openToast } from "../../components/toast";
import Slider from "react-slick";
import AddItemToCuration from "./AddItemToCuration";
import CategoriesDropDown from "../../components/categoriesDropDown";
import { getItemsFromCloset } from "../../apollo/operations/items";
import { addStyleMe } from "../../apollo/operations/styleme";

import {
  addFavorites,
  getFavoritesByItemIdOrCurationId,
  removeFavorites,
} from "../../apollo/operations/favorite";
import {
  setCuration,
  clearCuration,
  deleteCuration,
  setFlags,
  setStyleMeCuration,
  setBulkCuration,
} from "../../store/curationSlice";
import { getChildCategories, getMainCategory } from "../../components/helpers/categoryHelper";
import IconComponent from "../../components/fields/icon";
import {
  Clear,
  ContainerHeader,
  ContainerList,
  Curations,
  Header,
  InspiredContainer,
  ItemContainer,
  Label,
  MainContainer,
} from "../../containers/StyleMe";
import { clearStyleMe } from "../../store/socketSlice";
import Loader from "../../components/loaderScreen";
import useGetStyleMe from "../../hooks/useGetStyleMe";
import "./curations.css";

// Images
import SearchIcon from "../../assets/svg/search-icon.svg";

export default function StyleMe() {
  const { SelectedList, loading: styleMeloading } = useGetStyleMe();

  const sliderRef = createRef();
  const classes = useStyles();
  const skeletonclasses = skeletonStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const open = Boolean(anchorEl);
  const [curationsData, setCurationsData] = useState([]);

  const [inspiredData, setInspiredData] = useState([]);
  const [isInspiredData, setIsinspiredData] = useState(false);
  const [inspiredList, setInspiredList] = useState([]);
  const styleMeData = useSelector((state) => state.socket.styleMe);
  const styleMeCuration = useSelector((state) => state.curation.styleMeCuration);

  const [curationIndex, setCurationIndex] = useState(null);

  const curationsList = useSelector((state) => state.curation.curation);
  const curationsFlags = useSelector((state) => state.curation.flags);

  const [addCurationsPopup, setAddCurationsPopup] = useState(false);
  const [outFitsDetails, setOutFitsDetails] = useState({});
  const [showCategoryPopup, setShowCategoryPopup] = useState(false);
  const [curation, { data: addCurationData, loading: curationLoading }] = useMutation(addCurations);
  const [getCuration, { data: getCurationListData }] = useLazyQuery(getCurationsList);
  const [getChatResponse] = useLazyQuery(getChatResponseQuery);
  const [getSelectedLook] = useLazyQuery(getSelectedLookQuery);
  const [getRandomInspiredRequest, { data: getRandomInspiredData, loading }] =
    useLazyQuery(getRandomInspired);
  const [addFavorite, { data: addFavoriteData, loading: addFavoriteLoading }] =
    useMutation(addFavorites);
  const [getFavorite, { data: favoriteDataList }] = useLazyQuery(getFavoritesByItemIdOrCurationId);
  const [UpdateCuration, { data: updateCurationData, loading: UpdateCurationLoading }] =
    useMutation(updateCuration);
  const [deleteCurationList] = useMutation(removeCuration);
  const [deleteFavorite] = useMutation(removeFavorites);
  const dispatch = useDispatch();
  const [getItems, { data: ItemsList }] = useLazyQuery(getItemsFromCloset);
  const [categoryName, setCategoryName] = useState("");
  const [mainCategoryName, setMainCategoryName] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const categoriesList = useSelector((state) => state.category.category);
  const [productsList, setProductsList] = useState([]);
  const [allCurationData, setAllCurationData] = useState([]);
  const [updateStyleMeDB] = useMutation(addStyleMe);
  const [updateStyleMeFlag, setUpdateStyleMeFlag] = useState(false);
  const [slide, setSlide] = useState(0);

  const [activeTab, setActiveTab] = useState("CreateMyOwn");
  const [draggedItem, setDraggedItem] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [curationDataLoader, setcurationDataLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [getResults, setGetResults] = useState(false);
  const [chatResultLoading, setChatResultLoading] = useState(false);
  const [showChatModel, setShowChatModel] = useState(true);

  const linearCategories = useMemo(() => {
    let linearSubCategories = {};
    let mainCategory = "";
    categoriesList?.forEach((subCat) => {
      mainCategory = subCat.name;
      recursion(subCat);
    });

    function recursion(subCat) {
      linearSubCategories[subCat.name] = mainCategory;
      if (subCat.subCategories) {
        subCat.subCategories.forEach((subCategory) => {
          recursion(subCategory);
        });
      }
    }

    return linearSubCategories;
  }, [categoriesList]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (selectedSuggestionIndex >= 0) {
        setSearchInput(suggestions[selectedSuggestionIndex]);
        setSuggestions([]);
        handleSearchClick(event);
        setSelectedSuggestionIndex(-1);
      } else {
        handleSearchClick(event);
        setSuggestions([]);
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0,
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1,
      );
    }
  };

  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [suggestions]);

  useEffect(() => {
    if (activeTab !== "SearchResults") {
      setCurationsData([]);
      setcurationDataLoader(true);
      fetchCurationsData();
      setSelectedIndex(null);
    }
  }, [activeTab]);

  useEffect(() => {
    if (getResults) {
      setCurationsData([]);
      setSelectedIndex(null);
      setcurationDataLoader(true);
      fetchCurationsData();
      setGetResults(false);
    }
  }, [getResults]);

  useEffect(() => {
    if (refresh) {
      setCurationsData([]);
      setcurationDataLoader(true);
      fetchCurationsData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    linearCategories;
  }, [categoriesList]);

  // useEffect(() => {
  //   if (curationsData?.length && activeTab === "CreateMyOwn") {
  //     styleMeCuration.forEach((item) => {
  //       curationsData?.splice(Object.keys(item), 1, item[Object.keys(item)]);
  //     });
  //   }
  // }, [curationsData]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(clearCuration());
    dispatch(setBulkCuration(styleMeData?.SelectedList));
  }, [styleMeData]);

  useEffect(() => {
    // if (SelectedList.length) {
    dispatch(clearCuration());
    SelectedList.forEach((item) => {
      if (item.src) {
        dispatch(
          setCuration({
            ...item,
            mainCategory: getMainCategory(
              categoriesList?.length ? categoriesList : [],
              item?.category,
            ),
          }),
        );
      }
    });
    // setCurationsData(CurationDataList);
    // }
  }, [styleMeloading]);

  function updateToStyleDB(items, curation = []) {
    //send to db when customize curation
    updateStyleMeDB({
      variables: {
        styleMeData: {
          selectList: structuredClone(items.map((item) => `${item.id}`)),
          curationList: [],
        },
      },
    });
  }

  const [settings, setSettings] = useState({
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    adaptiveHeight: true,
    arrows: true,
    variableWidth: false,
    className: classes.sliderContainer,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          infinite: false,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 2,
          adaptiveHeight: true,
          arrows: true,
          variableWidth: false,
          className: classes.sliderContainer
        },
      },
      {
        breakpoint: 991,
        settings: {
          infinite: false,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 2,
          adaptiveHeight: true,
          arrows: true,
          variableWidth: false,
          className: classes.sliderContainer
        },
      },
      {
        breakpoint: 940,
        settings: {
          infinite: false,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 2,
          adaptiveHeight: true,
          arrows: true,
          variableWidth: false,
          className: classes.sliderContainer
        }
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 1,
          adaptiveHeight: true,
          arrows: true,
          variableWidth: false,
          className: classes.sliderContainer
        }
      },
      {
        breakpoint: 450,
        settings: {
          infinite: false,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 1,
          adaptiveHeight: true,
          arrows: true,
          variableWidth: false,
          className: classes.sliderContainer
        }
      }
    ]
  });

  function fetchCurationsData() {
    if (activeTab !== "SearchResults") {
      getSelectedLook({
        variables: { selectedLook: activeTab, refresh: refresh },
      })
        .then((response) => {
          setcurationDataLoader(false);
          setCurationsData(response?.data?.getSelectedLook.data);
          if (refresh && response?.data?.getSelectedLook.message.includes("Try after")) {
            // eslint-disable-next-line
            openToast(
              "warning",
              "Refresh Limit Excceded",
              `${response?.data?.getSelectedLook.message} for ${activeTab.replace(
                /([A-Z])/g,
                " $1",
              )} look`,
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching curations:", error);
          setCurationsData([]);
          setcurationDataLoader(false);
        });
    } else {
      getChatResponse({
        variables: { message: searchInput },
      })
        .then((response) => {
          setcurationDataLoader(false);
          setCurationsData(response?.data?.getChatResponse.data);
          if (
            response?.data?.getChatResponse.data?.length <= 0 ||
            !response?.data?.getChatResponse.data?.length
          ) {
            setShowChatModel(true);
          }
          setChatResultLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching curations:", error);
          setCurationsData([]);
          setcurationDataLoader(false);
          setChatResultLoading(false);
          setShowChatModel(true);
        });
    }
  }
  function showAddCurationsPopup(value, index) {
    if (value) {
      setCurationIndex(index);
      setAddCurationsPopup(true);
    } else {
      setAddCurationsPopup(false);
    }
  }
  useEffect(() => {
    if (addCurationData && addCurationData.addCurations) {
      if (addCurationData.addCurations?.statusCode === "200") {
        if (outFitsDetails.isLiked) {
          addFavorite({
            variables: {
              favoriteData: {
                curationId: addCurationData.addCurations.data.id.toString(),
                isLiked: true,
              },
            },
          });
          if (isInspiredData) {
            let temp = structuredClone(inspiredData);
            temp[outFitsDetails.index].isLiked = true;
            setInspiredData(temp);
          }
          setCurationId(addCurationData.addCurations.data.id.toString());
        } else {
          openToast(
            "success",
            "Successful!",
            `Curation added ${
              addCurationData.addCurations.data?.curationName
                ? `as ${addCurationData.addCurations.data?.curationName}`
                : ""
            }`,
          );
        }
        setAddCurationsPopup(false);
      } else {
        openToast("error", "Failed!", "Try Again");
        handleCurationType("");
      }
    }
  }, [addCurationData]);

  useEffect(() => {
    if (addFavoriteData && addFavoriteData.addFavorites?.statusCode === "200") {
      openToast(
        "success",
        "Successful!",
        `Curation added to favorite  ${
          addCurationData.addCurations.data?.curationName
            ? `as ${addCurationData.addCurations.data?.curationName}`
            : ""
        }`,
      );
      setIsinspiredData(false);
    }
  }, [addFavoriteData]);

  function addCurationsDetails(value) {
    if (value) {
      curation({
        variables: {
          curationData: {
            ...value,
          },
        },
      });
      if (outFitsDetails.isLiked && !isInspiredData) {
        setCurationsData((prevCurationsData) => {
          const updatedCurationsData = prevCurationsData.map((item, i) => {
            if (i === outFitsDetails.index) {
              return { ...item, isLiked: true };
            }
            return item;
          });
          return updatedCurationsData;
        });
      } else if (isInspiredData && outFitsDetails.isLiked) {
        let temp = structuredClone(inspiredData);
        temp[outFitsDetails.index].isLiked = true;
        setInspiredData(temp);
      } else {
        setCurationsData((prevCurationsData) => {
          const updatedCurationsData = prevCurationsData.map((item, i) => {
            if (i === outFitsDetails.index) {
              return { ...item, isAdded: true };
            }
            return item;
          });
          return updatedCurationsData;
        });
      }
    }
  }

  useEffect(() => {
    getCuration({ variables: {} });
  }, [addCurationData, addFavoriteData, updateCurationData]);

  useEffect(() => {
    setInspiredData([]);
    getRandomInspiredRequest({ variables: {} });
  }, []);

  useEffect(() => {
    getFavorite({ variables: {} });
  }, [addFavoriteData]);

  useEffect(() => {
    if (searchInput !== "" && !lookTypes.includes(searchInput)) {
      const filteredSuggestions = lookTypes
        .filter((look) => look?.toLowerCase().includes(searchInput?.toLowerCase()))
        .slice(0, 3);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [searchInput]);

  function addCurationToFavorite(favoriteDetails) {
    curationsData[favoriteDetails.index].isLiked = favoriteDetails.isLiked;
    setCurationsData([...curationsData]);
    if (
      getCurationListData?.getCurationsList?.data &&
      getCurationListData?.getCurationsList?.data?.length > 0
    ) {
      let favoriteData = getCurationListData?.getCurationsList?.data;
      let curationId;
      favoriteData.forEach((item) => {
        let data = 0;
        if (item.curation.length === favoriteDetails.curation.length) {
          item.curation.forEach((i) => {
            if (favoriteDetails.curation.includes(i)) data++;
          });
        }
        if (data === favoriteDetails.curation.length) {
          curationId = item.id;
        }
      });
      if (curationId) {
        addFavorite({
          variables: {
            favoriteData: {
              curationId: curationId.toString(),
              isLiked: true,
            },
          },
        });
        UpdateCuration({
          variables: {
            curationData: {
              curationId: curationId.toString(),
              isLiked: true,
            },
          },
        });
      } else {
        setAddCurationsPopup(true);
      }
    }
  }

  function addOutfits(outfitData) {
    setOutFitsDetails(outfitData);
  }

  const onDragStartItem = (e) => {
    const matchCategory =
      curationsList[e]?.mainCategory?.toLowerCase() || curationsList[e]?.category?.toLowerCase();
    if (e >= 0) {
      if (["skirts", "shorts", "pants", "denim"].includes(matchCategory)) {
        setDraggedItem({ categoryGroup: "bottomWear", ...curationsList[e] });
      } else if (["tops", "shirts", "tshirts"].includes(matchCategory)) {
        setDraggedItem({ categoryGroup: "topWear", ...curationsList[e] });
      } else if (
        [
          "dresses",
          "loungewear",
          "beachwear",
          "activewear",
          "miscellaneous",
          "jumpsuit",
          "suits",
        ].includes(matchCategory)
      ) {
        setDraggedItem({ categoryGroup: "completeWear", ...curationsList[e] });
      } else {
        if (["beauty", "accessories"].includes(matchCategory)) {
          setDraggedItem({ categoryGroup: curationsList[e].category, ...curationsList[e] });
        } else {
          setDraggedItem({ categoryGroup: matchCategory, ...curationsList[e] });
        }
      }
    }
  };

  const customCurationData = (data, index) => {
    let customData = {};
    let styleMeFilterData = styleMeCuration.filter((item) => Object.keys(item) !== index);
    customData[index] = data[index];
    const setCustomData = styleMeFilterData.concat(customData);
    return setCustomData;
  };

  const onDropCurationContainer = (e, droppedOnItemId) => {
    const customDataSave = curationsData;
    const matchCategory =
      draggedItem?.mainCategory?.toLowerCase() || draggedItem?.category?.toLowerCase();
    let draggedItemData = { ...draggedItem };
    draggedItemData.mainCategory = linearCategories[draggedItem.category];
    if (draggedItem.categoryGroup === "undefined") {
      if (["skirts", "shorts", "pants", "denim"].includes(matchCategory)) {
        draggedItemData.categoryGroup = "bottomWear";
      } else if (["tops", "shirts", "tshirts"].includes(matchCategory)) {
        draggedItemData.categoryGroup = "topWear";
      } else if (
        [
          "dresses",
          "loungewear",
          "beachwear",
          "activewear",
          "miscellaneous",
          "jumpsuit",
          "suits",
        ].includes(matchCategory)
      ) {
        draggedItemData.categoryGroup = "completeWear";
      } else {
        if (["beauty", "accessories"].includes(matchCategory)) {
          draggedItemData.categoryGroup = "accessories";
          draggedItemData.mainCategory = "Accessories";
          draggedItemData.category = "Accessories";
        } else {
          draggedItemData.categoryGroup = matchCategory;
        }
      }
    }

    const updatedItem = {
      ...draggedItemData,
      categoryGroup: draggedItemData?.mainCategory.includes("Top")
        ? "topWear"
        : draggedItemData.mainCategory.includes("Beauty")
        ? "accessories"
        : draggedItemData.categoryGroup,
      mainCategory: draggedItemData.mainCategory.includes("Beauty")
        ? "Accessories"
        : draggedItemData.mainCategory,
      category: draggedItemData.mainCategory.includes("Beauty")
        ? "Accessories"
        : draggedItem.category,
    };

    setDraggedItem({
      ...updatedItem,
    });

    const checkExistItem = customDataSave[e]?.curations?.filter(
      (data) => data?.categoryGroup?.toLowerCase() === updatedItem?.categoryGroup?.toLowerCase(),
    );

    const existingCategory = customDataSave[e]?.curations?.reduce((category, cur) => {
      category.push(cur.categoryGroup);
      return category;
    }, []);

    // Check if there are already 2 "accessories"
    const accessories = customDataSave[e]?.curations?.filter(
      (cur) =>
        cur.mainCategory.toLowerCase() === "accessories" ||
        cur.mainCategory.toLowerCase() === "beauty",
    );
    let accessoriesLength;
    if (existingCategory.includes("Outerwear") || existingCategory.includes("outerwear")) {
      accessoriesLength = 1;
    } else {
      accessoriesLength = 2;
    }
    if (
      accessories.length >= accessoriesLength &&
      updatedItem.mainCategory.toLowerCase() === "accessories"
    ) {
      let temp = structuredClone(customDataSave);

      // Find an accessory with the same subcategory
      let matchIndex = accessories.findIndex(
        (cur) => cur.subCategory?.toLowerCase() === updatedItem.subCategory?.toLowerCase(),
      );
      if (matchIndex === -1) {
        if (droppedOnItemId) {
          matchIndex = temp[e].curations.findIndex((cur) => cur.src === droppedOnItemId);
        } else {
          matchIndex = temp[e].curations.findIndex(
            (cur) => cur.mainCategory.toLowerCase() === "accessories",
          );
        }
      } else {
        // Find the actual index in the original array
        matchIndex = temp[e].curations.findIndex(
          (cur) =>
            cur.mainCategory.toLowerCase() === "accessories" &&
            cur.subCategory?.toLowerCase() === updatedItem.subCategory?.toLowerCase(),
        );
      }

      if (matchIndex !== -1) {
        temp[e].curations[matchIndex] = updatedItem;
      }
      temp[e].isLiked = false;
      temp[e].isAdded = false;

      setCurationsData(temp);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
    } else if (
      accessories.length < accessoriesLength &&
      updatedItem.mainCategory.toLowerCase() === "accessories"
    ) {
      let temp = structuredClone(customDataSave);
      let matchIndex = temp[e].curations.findIndex(
        (cur) =>
          cur.mainCategory.toLowerCase() === "accessories" &&
          cur.subCategory?.toLowerCase() === updatedItem.subCategory?.toLowerCase(),
      );
      if (matchIndex !== -1) {
        temp[e].curations[matchIndex] = updatedItem;
      } else {
        temp[e]?.curations?.push(updatedItem);
      }
      temp[e].isLiked = false;
      temp[e].isAdded = false;

      setCurationsData(temp);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
    } else if (updatedItem.mainCategory.toLowerCase() === "outerwear" && accessories.length >= 2) {
      let temp = structuredClone(customDataSave);
      let matchIndex = temp[e].curations.findIndex(
        (cur) => cur.mainCategory.toLowerCase() === "accessories",
      );
      if (matchIndex !== -1) {
        temp[e].curations[matchIndex] = updatedItem;
      }

      temp[e].isLiked = false;
      temp[e].isAdded = false;

      setCurationsData(temp);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
    } else if (checkExistItem?.length > 0) {
      let temp = structuredClone(customDataSave);
      let swapData = temp[e].curations.map((data) => {
        if (data?.categoryGroup?.toLowerCase() === updatedItem?.categoryGroup?.toLowerCase()) {
          data = updatedItem;
        }
        return data;
      });
      temp[e].curations = swapData.filter(
        (obj, index) => swapData.findIndex((item) => item.id === obj.id) === index,
      );

      temp[e].isLiked = false;
      temp[e].isAdded = false;

      setCurationsData(temp);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
      // openToast("error", "Failed!", "Restricted Item");
    } else if (
      ((existingCategory.includes("topWear") ||
        existingCategory.includes("bottomWear") ||
        existingCategory.includes("Sweaters")) &&
        updatedItem.categoryGroup === "completeWear") ||
      (existingCategory.includes("completeWear") &&
        (updatedItem.categoryGroup === "topWear" ||
          updatedItem.categoryGroup === "bottomWear" ||
          updatedItem.categoryGroup === "Sweaters"))
    ) {
      // this code needs optimization **
      let temp = structuredClone(curationsData);
      let swapData = temp[e].curations.map((data) => {
        if (
          ((data?.categoryGroup?.includes("topWear") ||
            data?.categoryGroup?.includes("bottomWear") ||
            data?.categoryGroup?.includes("Sweaters")) &&
            updatedItem.categoryGroup === "completeWear") ||
          (data?.categoryGroup?.includes("completeWear") &&
            (updatedItem.categoryGroup === "topWear" ||
              updatedItem.categoryGroup === "bottomWear" ||
              updatedItem.categoryGroup === "Sweaters"))
        ) {
          data = updatedItem;
        }
        return data;
      });
      temp[e].curations = swapData.filter(
        (obj, index) => swapData.findIndex((item) => item.id === obj.id) === index,
      );
      temp[e].isLiked = false;
      temp[e].isAdded = false;
      setCurationsData(temp);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
      // openToast("error", "Failed!", "Restricted Item");
    } else {
      let temp = structuredClone(curationsData);
      temp[e]?.curations?.push(updatedItem);
      // curationsData[e]?.curations?.push(draggedItem);
      if (e === selectedIndex) setSelectedIndex(0);
      // setCurationsData([...curationsData.splice(e, 1), ...curationsData]);
      temp[e].isLiked = false;
      temp[e].isAdded = false;
      setCurationsData([...temp]);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
    }
  };

  useEffect(() => {
    if (updateStyleMeFlag || curationsFlags.updateStyleMeDb) {
      updateToStyleDB(curationsList);
      setUpdateStyleMeFlag(false);
      dispatch(setFlags({ updateStyleMeDb: false }));
    }
    setInspiredData(getRandomInspiredData?.getRandomInspired?.data);
  }, [curationsList, loading]);

  function showMenuPopup(category, value) {
    let mainCategory = getMainCategory(categoriesList, category);
    let categoryName = getChildCategories(categoriesList, category, mainCategory);
    getItems({ variables: { category: categoryName } });
    setCategoryName(category);
    setMainCategoryName(mainCategory);
    setShowCategoryPopup(value);
  }
  useEffect(() => {
    if (ItemsList && ItemsList.getItemsFromCloset && ItemsList.getItemsFromCloset.length > 0) {
      let itemsList = ItemsList.getItemsFromCloset.map((data) => ({
        ...data,
        isSelected: false,
        isLiked: false,
      }));
      const uniqueCur = itemsList?.map((item) =>
        curationsList?.findIndex((cur) => cur.id === item.id),
      );
      uniqueCur?.forEach((item, index) => {
        if (item >= 0) {
          itemsList[index] = { ...curationsList[item], isSelected: true, isLiked: false };
        }
        setProductsList(itemsList);
      });
    } else {
      setProductsList([]);
    }
  }, [ItemsList, curationsList]);

  function addItemModalButton(value) {
    setShowConfirmationModal(false);
    if (value) {
      setCurationsData([]);
      dispatch(clearCuration());
      dispatch(clearStyleMe());
      updateToStyleDB([], []);
      dispatch(setStyleMeCuration([]));
      setCurationsData([]);
    }
  }

  function clearCurationData() {
    setShowConfirmationModal(true);
  }
  function removeSelectedItem(itemIndex) {
    dispatch(deleteCuration(itemIndex));
    setUpdateStyleMeFlag(true);
  }
  function removeAddCurationItem(itemIds, index) {
    curationsData[index].isAdded = false;
    setCurationsData([...curationsData]);
    if (allCurationData.length > 0) {
      let curationData = [...allCurationData];
      let curationId;
      curationData.forEach((item) => {
        let data = 0;
        if (item.curation.length === itemIds.length) {
          item.curation.forEach((i) => {
            if (itemIds.includes(i)) data++;
          });
        }
        if (data === itemIds.length) {
          curationId = item.id;
        }
      });

      if (curationId) {
        deleteCurationList({
          variables: {
            curationId: curationId.toString(),
          },
        });
      }
    }
  }

  function removeCurationItem(itemID, index) {
    const customDataSave = curationsData;
    let temp = structuredClone(customDataSave);
    if (temp[index]?.curations) {
      temp[index].curations = temp[index].curations.filter((citem) => citem.id !== itemID);
      // temp[index]?.curations?.splice(itemIndex, 1);
    } else {
      temp[index].curations = temp[index].curations.filter((citem) => citem.id !== itemID);
    }
    temp[index].isAdded = false;
    temp[index].isLiked = false;
    // temp[index]?.curations?.splice(itemIndex, 1);
    if (index === selectedIndex) setSelectedIndex(0);
    // setCurationsData([...curationsData.splice(index, 1), ...curationsData]);
    temp = temp.filter((item) => item.curations.length > 0);
    setCurationsData([...temp]);
    dispatch(setStyleMeCuration(customCurationData(temp, index)));
  }

  useEffect(() => {
    if (
      getCurationListData?.getCurationsList?.data &&
      getCurationListData?.getCurationsList?.data?.length > 0
    ) {
      setAllCurationData(getCurationListData?.getCurationsList?.data);
    }
  }, [getCurationListData, updateCurationData, addCurationData]);

  useEffect(() => {
    if (
      getCurationListData?.getCurationsList?.data &&
      getCurationListData?.getCurationsList?.data?.length > 0
    ) {
      setAllCurationData(getCurationListData?.getCurationsList?.data);
    }
  }, [searchInput, getResults]);

  function removeFromFavorite(itemIds, index) {
    curationsData[index].isLiked = false;
    setCurationsData([...curationsData]);
    if (allCurationData.length > 0) {
      let curationData = [...allCurationData];
      let curationId;
      curationData.forEach((item) => {
        let data = 0;
        if (item.curation.length === itemIds.length) {
          item.curation.forEach((i) => {
            if (itemIds.includes(i)) data++;
          });
        }
        if (data === itemIds.length) {
          curationId = item.id;
        }
      });

      if (curationId) {
        deleteFavorite({
          variables: {
            favoriteData: {
              curationId: curationId.toString(),
            },
          },
        });

        if (curationsData[index].isAdded) {
          UpdateCuration({
            variables: {
              curationData: {
                curationId: curationId.toString(),
                isLiked: false,
              },
            },
          });
        } else {
          deleteCurationList({
            variables: {
              curationId: curationId.toString(),
            },
          });
        }
      }
    }
  }

  function removeFromFavGetInspired(curationId, index) {
    if (curationId) {
      deleteFavorite({
        variables: {
          favoriteData: {
            curationId: curationId.toString(),
          },
        },
      });

      if (inspiredData[index].isLiked) {
        UpdateCuration({
          variables: {
            curationData: {
              curationId: curationId.toString(),
              isLiked: false,
            },
          },
        });
      } else {
        deleteCurationList({
          variables: {
            curationId: curationId.toString(),
          },
        });
      }
    }
    let temp = JSON.parse(JSON.stringify(inspiredData));
    temp[0].isLiked = false;
    setInspiredData(temp);
  }

  function handleSearchClick(e) {
    if (searchInput !== "") {
      setActiveTab("SearchResults");
      setChatResultLoading(true);
      setGetResults(true);
    }
  }

  function handleMenuClick(e) {
    showMenuPopup(e, true);
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    return setAnchorEl(event.currentTarget);
  }

  function deleteCurationItem() {
    setCurationsData(curationsData.filter((item, i) => i !== selectedIndex));
    setSelectedIndex(null);
  }

  const handleSelected = (index) => {
    setSelectedIndex((pre) => (pre === index ? null : index));
  };

  function addNewCurationsItem() {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
    setCurationsData([
      {
        curations: [],
        isLiked: false,
        isAdded: false,
        selected: false,
      },
      ...(Array.isArray(curationsData) ? curationsData : []),
    ]);
    openToast("success", "Successful!", "Curation added");
  }

  function handleResize() {
    if (window.innerWidth > 450) {
      setSettings({ ...settings, slidesToShow: Math.floor(window.innerWidth / 430) });
    } else if (window.innerWidth <= 450) {
      setSettings({ ...settings, slidesToShow: Math.floor(window.innerWidth / 330) });
    }
  }

  function setCurationId(id) {
    if (isInspiredData === true) {
      setInspiredData(
        inspiredData.map((item, index) => {
          if (index === curationIndex) {
            return { ...item, id: id };
          }
          return item;
        }),
      );
    } else {
      setCurationsData(
        curationsData.map((item, index) => {
          if (index === curationIndex) {
            return { ...item, id: id };
          }
          return item;
        }),
      );
    }
  }

  function addToIsAdded(id, index) {
    UpdateCuration({
      variables: {
        curationData: {
          curationId: id,
          isAdded: true,
          isDeleted: false
        },
      },
    });

    setCurationsData(
      curationsData.map((item, i) => {
        if (i === index) {
          item.id = id;
          item.isAdded = true;
        }
        return item;
      }),
    );
  }

  const [curationType, setCurationType] = useState("");

  function handleCurationType(value) {
    setCurationType(value);
  }

  const handleSlideChange = (slide) => {
    setSlide(slide);
  };

  return (
    <>
      <div className="custom-new-navbar">
        <Header className="new-header">
          <h1> Hello! I am your personal stylist. What can I help you style? </h1>
          <form className="search-form">
            <div className="input-group">
              <input
                name="search"
                className={"form-control"}
                type="search"
                placeholder="Enter a look you want to style."
                id="search-input"
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsSearchActive(true)}
                onBlur={() => setIsSearchActive(false)}
              />
              <span className="input-group-append">
                <button className="btn">
                  <img className="search-icon" src={SearchIcon} alt="SearchIcon" />
                </button>
                <span className={`${isSearchActive && "activeSearch"}`}>I’m looking for:</span>
              </span>
            </div>
            <button
              className="btn submit-btn"
              onClick={(event) => {
                event.preventDefault();
                handleSearchClick(event);
              }}
            >
              Go
            </button>
            <div className="suggestions-dropdown-box">
              {suggestions.length > 0 && (
                <ul className="suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setSearchInput(suggestion);
                        setSuggestions([]);
                      }}
                      className={`suggestion-item ${
                        index === selectedSuggestionIndex ? "active-suggestion" : ""
                      }`}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </form>
        </Header>
        <ul className="custom-nav-list">
          <li
            onClick={() => {
              setActiveTab("CreateMyOwn");
              setSearchInput("");
            }}
            className={activeTab === "CreateMyOwn" ? "active" : ""}
          >
            Create My Own
          </li>
          <li
            onClick={() => {
              setActiveTab("DateNight");
              setSearchInput("");
            }}
            className={activeTab === "DateNight" ? "active" : ""}
          >
            Date Night
          </li>
          <li
            onClick={() => {
              setActiveTab("SummerTime");
              setSearchInput("");
            }}
            className={activeTab === "SummerTime" ? "active" : ""}
          >
            Summer Time
          </li>
          <li
            onClick={() => {
              setActiveTab("WeekendBrunch");
              setSearchInput("");
            }}
            className={activeTab === "WeekendBrunch" ? "active" : ""}
          >
            Weekend Brunch
          </li>
          <li
            onClick={() => {
              setActiveTab("CasualDinner");
              setSearchInput("");
            }}
            className={activeTab === "CasualDinner" ? "active" : ""}
          >
            Casual Dinner
          </li>
          <li
            onClick={() => {
              setActiveTab("BusinessCasual");
              setSearchInput("");
            }}
            className={activeTab === "BusinessCasual" ? "active" : ""}
          >
            Business Casual
          </li>
        </ul>
      </div>

      <MainContainer className="custom-main-page-wrapper">
        {activeTab === "CreateMyOwn" && (
          <ItemContainer>
            <ContainerHeader>
              {curationsList?.length > 0 ? (
                <div className="title-header">
                  <h2>SELECTED ITEMS</h2>
                  <h2>PICK YOUR STYLE</h2>
                </div>
              ) : (
                <div className="add-items-text">
                  <h2>ADD ITEMS</h2>
                </div>
              )}

              {(curationsList?.length) ? (
                <Clear>
                  <p onClick={clearCurationData}>Clear All</p>
                </Clear>
              ) : (
                ""
              )}
            </ContainerHeader>
            {showConfirmationModal && (
              <ModalPortal>
                <Modal
                  type="clearSelected"
                  handleButtonClick={addItemModalButton}
                  svgType="circle"
                />
              </ModalPortal>
            )}
            <ContainerList>
              {curationsList?.length
                ? curationsList?.map((item, index) => (
                    <SelectedItems
                      mainCat={item.mainCategory}
                      itemCat={item.category}
                      item={item?.src}
                      index={index}
                      key={index}
                      onDragStartItem={onDragStartItem}
                      removeSelectedItem={removeSelectedItem}
                    />
                  ))
                : undefined}

              <div className={classes.addItemButton}>
                <div className={classes.addbutton} onClick={handleClick}>
                  <IconComponent iconTitle={"NewAddPlus"} />
                </div>
                <CategoriesDropDown
                  selectedCategory={"shirts"}
                  handleClose={handleClose}
                  handleOpen={handleClick}
                  categoriesList={categoriesList}
                  open={open}
                  handleMenuClick={handleMenuClick}
                />
              </div>
            </ContainerList>
          </ItemContainer>
        )}

        {(activeTab !== "SearchResults" || (curationsData?.length > 0 && activeTab === "SearchResults")) ?
          (<Curations>
            <ContainerHeader style={{ justifyContent: "space-between", flexDirection: "column" }}>
              {activeTab === "CreateMyOwn" ? (
                <>&#8203;</>
              ) : (
                <Label className="curate-look-text your-class">CURATED LOOKS</Label>
              )}
              {(activeTab !== "SearchResults" && !curationDataLoader && curationsList?.length > 0) && (
                <>
                  <div className="refresh-pagination-row">
                    <Clear>
                      <p
                        onClick={() => {
                          setRefresh(true);
                        }}
                      >
                        Refresh
                      </p>
                    </Clear>
                    {((activeTab !== "CreateMyOwn" && curationsData?.length > 0) || (curationsList?.length > 0 && activeTab === "CreateMyOwn")) && (
                      <Pagination
                        curationsData={curationsData?.length > 0 ? curationsData?.length : 0}
                        sliderRef={sliderRef}
                        slide={slide}
                      />
                    )}
                  </div>
                </>
              )}
            </ContainerHeader>
            {/* {curationDataLoader || !curationDataLoader? ( */}
            {curationDataLoader ? (
              <>
                <div className="skeleton-loader-wrapper curation-slider-loader">
                  {" "}
                  {Array(3)
                    .fill(null)
                    .map((_, index) => (
                      <div key={index} className={"get-inspired-skeleton"}>
                        <Skeleton className={"get-inspired-skeleton-child"} />
                      </div>
                    ))}
                </div>
              </>
            ) : activeTab !== "SearchResults" ? (
              <>
                {curationsData?.length > 0 ? (
                  <Slider ref={sliderRef} {...settings} afterChange={handleSlideChange}>
                    {curationsData?.map((outfits, index) => (
                      <CurationsContainer
                        key={`curation_${index}`}
                        outfits={outfits?.curations}
                        linearCategories={linearCategories}
                        id={outfits?.id}
                        index={index}
                        showAddCurationsPopup={showAddCurationsPopup}
                        addOutfits={addOutfits}
                        isAdded={outfits?.isAdded}
                        isLiked={outfits?.isLiked}
                        isSelected={index === selectedIndex}
                        handleSelected={handleSelected}
                        onDropCurationContainer={onDropCurationContainer}
                        removeCurationItem={removeCurationItem}
                        addCurationToFavorite={addCurationToFavorite}
                        addToIsAdded={addToIsAdded}
                        removeFromFavorite={removeFromFavorite}
                        removeAddCurationItem={removeAddCurationItem}
                        showCrossIcon={activeTab === "CreateMyOwn" ? true : false}
                      />
                    ))}
                  </Slider>
                ) : (
                  <>
                    {!curationDataLoader && !curationsData?.length && curationsList.length > 0 && (
                      <div className={classes.dataNotFound}>No Items Found Here</div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="search-result-grid-boxes-wrapper">
                {curationsData?.length > 0 &&
                  curationsData?.map((outfits, index) => (
                    <div className="result-grid-box" key={`curation_${index}`}>
                      <CurationsContainer
                        outfits={outfits?.curations}
                        linearCategories={linearCategories}
                        id={outfits?.id}
                        index={index}
                        showAddCurationsPopup={showAddCurationsPopup}
                        addOutfits={addOutfits}
                        isAdded={outfits?.isAdded}
                        isLiked={outfits?.isLiked}
                        isSelected={index === selectedIndex}
                        handleSelected={handleSelected}
                        onDropCurationContainer={onDropCurationContainer}
                        removeCurationItem={removeCurationItem}
                        addCurationToFavorite={addCurationToFavorite}
                        addToIsAdded={addToIsAdded}
                        removeFromFavorite={removeFromFavorite}
                        removeAddCurationItem={removeAddCurationItem}
                        showCrossIcon={activeTab === "CreateMyOwn" ? true : false}
                      />
                    </div>
                  ))}
              </div>
            )}
            {curationsList?.length > 0 && activeTab === "CreateMyOwn" && (
              <div className={classes.curationActions}>
                <IconComponent
                  iconTitle="NewTrash"
                  width={"100%"}
                  height={"100%"}
                  className={classes.actionMyCuration}
                  style={{
                    opacity: selectedIndex !== null ? 1 : 0.5,
                    pointerEvents: selectedIndex !== null ? "all" : "none",
                  }}
                  onClick={deleteCurationItem}
                />
                <IconComponent
                  iconTitle="NewAddCircle"
                  width={"100%"}
                  height={"100%"}
                  className={classes.actionMyCuration}
                  onClick={addNewCurationsItem}
                />
              </div>
            )}
            {addCurationsPopup && (
              <AddCurations
                favoriteDataList={favoriteDataList}
                allCurationData={allCurationData}
                outfits={outFitsDetails}
                showAddCurationsPopup={showAddCurationsPopup}
                addCurationsDetails={addCurationsDetails}
              />
            )}
            {showCategoryPopup && (
              <AddItemToCuration
                categoryProductsList={productsList}
                categoryName={categoryName}
                mainCategoryName={mainCategoryName}
                handleResize={handleResize}
                setShowCategoryPopup={setShowCategoryPopup}
                setUpdateStyleMeFlag={setUpdateStyleMeFlag}
              />
            )}
          </Curations>
        ) : (
          <>
            {(!curationDataLoader && !curationsData?.length) && (
              <>
                {showChatModel && (
                  <ModalPortal>
                    <Modal
                      type="chatItemNotFound"
                      handleButtonClick={() => setShowChatModel(false)}
                      isChatResults={true}
                      svgType="circle"
                    />
                  </ModalPortal>
                )}
              </>
            )}
            {addCurationsPopup && (
              <AddCurations
                favoriteDataList={favoriteDataList}
                allCurationData={allCurationData}
                outfits={outFitsDetails}
                showAddCurationsPopup={showAddCurationsPopup}
                addCurationsDetails={addCurationsDetails}
              />
            )}
            {showCategoryPopup && (
              <AddItemToCuration
                categoryProductsList={productsList}
                categoryName={categoryName}
                mainCategoryName={mainCategoryName}
                handleResize={handleResize}
                setShowCategoryPopup={setShowCategoryPopup}
                setUpdateStyleMeFlag={setUpdateStyleMeFlag}
              />
            )}
          </>
        )}

        <Curations>
          <ContainerHeader className="get-inspired-title">
            <h2>FEATURED LOOKS</h2>
            <p>Get inspired from your own closet!</p>
          </ContainerHeader>
        </Curations>
        {/* {loading || !loading ? ( */}
        {loading ? (
          <>
            <div className="skeleton-loader-wrapper">
              {" "}
              {Array(2)
                .fill(null)
                .map((_, index) => (
                  <div key={index} className={"get-inspired-skeleton"}>
                    <Skeleton className={"get-inspired-skeleton-child"} />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <InspiredContainer>
            {inspiredData?.length > 0
              ? inspiredData?.slice(0, 2).map((outfits, index) => {
                  const curations = outfits?.curations.filter((cur) => cur.src);
                  return (
                    curations.length > 0 && (
                      <Inspired
                        outfits={curations}
                        key={index}
                        index={index}
                        handleCurationType={handleCurationType}
                        showAddCurationsPopup={showAddCurationsPopup}
                        addCurationToFavorite={addCurationToFavorite}
                        addOutfits={addOutfits}
                        isLiked={outfits?.isLiked}
                        removeFromFavGetInspired={removeFromFavGetInspired}
                        id={outfits?.id}
                        setIsInspiredData={setIsinspiredData}
                      />
                    )
                  );
                })
              : !loading &&
                !inspiredData?.length && (
                  <div className={classes.dataNotFound}>No Items Found Here</div>
                )}
          </InspiredContainer>
        )}
        {/* curationLoading || addFavoriteLoading || getFavoriteLoading || UpdateCurationLoading */}
        {(curationLoading || addFavoriteLoading || UpdateCurationLoading || chatResultLoading) && (
          <Loader />
        )}
        <div className="bottom-fixed-text">
          <p>Powered by AI</p>
        </div>
      </MainContainer>
    </>
  );
}
