import { useStyles } from "./styles";
import { useState } from "react";
import IconComponent from "../../../components/fields/icon";
import { FavList } from "../../../containers/StyleMe";
import CurationBoxes from "../../../components/curationBoxes/curationBox";
export default function FavoriteLists(props) {
  const { outfits, index, likedItem } = props;
  const [isLiked, setIsLiked] = useState(false);
  const classes = useStyles();

  function handleLike(value) {
    setIsLiked(!isLiked);
    likedItem(value, outfits);
  }

  return (
    <div className={classes.FavoritesDetails}>
      <div className={classes.FavoritesListContainer} id={index}>
        <IconComponent
          className={`${classes.actionField} ${classes.likeVector}`}
          iconTitle="LikedVector"
          onClick={() => {
            handleLike(true);
          }}
        />
        <div className={classes.FavoritesListImages}>
          <CurationBoxes
              curations={outfits?.curationList}
            />
        </div>
      </div>
      <div className={classes.itemDetails}>
        {outfits?.curationName
          ? outfits?.curationName
          : outfits?.itemName
          ? outfits?.itemName
          : outfits?.itemList?.itemName}
      </div>
    </div>
  );
}
