import IconComponent from "../../../components/fields/icon";
import { List } from "../../../containers/StyleMe";

export default function OuterwearTag({ outerwearList, removeCurationItem, parentIndex, classes, accessoriesLength , footwearLength, showCrossIcon }) {
  return (

    <div style={{ gridColumn: "2" }} className={`outerwearList${outerwearList.length} ${(accessoriesLength <= 0) && "outWearWrapper"} ${(footwearLength <= 0) && "outWearOne"}`}>
      {outerwearList.map((item, itemIndex) => (
        <List
          key={`index-${itemIndex}`}
          style={{ position: "relative", justifyContent: "center", display: "flex" }}
          data-category={item.category}
        >
          {
            showCrossIcon && (<IconComponent
              style={{ position: "absolute" }}
              iconTitle="CrossIcon"
              className={classes.trash}
              onClick={(event) => {
                event.stopPropagation();
                removeCurationItem(item.id, parentIndex);
              }}
            />)
          }

          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100px",
              minWidth: "100%",
              minHeight: "100px",
              width: "100%",
              objectFit: "contain",
            }}
            draggable={false}
            className={classes.outfitImage}
            src={item.src}
            key={`src-${itemIndex}`}
          />
        </List>
      ))}
    </div>
  );
}
